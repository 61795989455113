import { Heading, Box, Checkbox, FormLabel, useToast,Button, Text, InputGroup, Textarea, InputLeftElement, InputRightElement, Center, HStack, VStack, Image, Input, Select, Option, Container, useBreakpointValue, FormControl, FormErrorMessage, 
AlertDialog, InputLeftAddon, AlertDialogOverlay, AlertDialogContent, AlertDialogHeader, AlertDialogCloseButton, AlertDialogBody, Flex, Radio, RadioGroup } from "@chakra-ui/react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {Formik, Form, Field, ErrorMessage, FormikConsumer} from 'formik' ;
import * as Yup from 'yup'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { Link } from "react-router-dom";

import { faImagePortrait, faAngleDown, faWarning, faCalendarAlt, faWineGlassEmpty, faClock, faClockFour, faTimesCircle, faTimesSquare, faClockRotateLeft, faCalendar, faCheckCircle} from "@fortawesome/free-solid-svg-icons";
import {Stepper, Step, StepDescription, StepIcon, StepIndicator, StepNumber, StepSeparator, StepStatus, StepTitle, useSteps} from '@chakra-ui/react'

import lemonc from "./Assets/lemonc.jpg"
import chefs from "./Assets/chefs.jpg"
import grilledFish from "./Assets/grilledFish.jpg"
import restaurant from "./Assets/restaurant.jpg"
import indoor from "./Assets/indoor.jpg"
import pastalm from "./Assets/pastalm.jpg"


import OrderOnline from "./OrderOnline";
import ReservationMob from "./ReservationMob";
import { faPhone,  faWineGlass, faUser } from "@fortawesome/free-solid-svg-icons";
import PhoneInput from 'react-phone-number-input'
import flags from "react-phone-number-input/flags";
import app from "./firebase"
import {getFirestore, addDoc, collection} from "firebase/firestore"
import axios from 'axios'
import { format } from "date-fns";




function Reservation() {

    let [bookDate, setbookDate] = useState()
    let [bookTime, setBookTime] = useState()


    // let userDate = new Date()
    // let year = userDate.getFullYear();
    // let month = (userDate.getMonth()+1).toString().padStart(2, '0')
    // let day =userDate.getDate().toString().padStart(2, '0')
 
    // let formatDate = `${year}-${month}-${day}`
 

    
 //Creating Form validation
 let [initialValues, setInitialvalues] = useState({
    activity: "",
    date: new Date(),  //toDateString() is only used when in JSX
    occasion: "",
    diners: "",
    time: ""
  })

  

  let validationSchema = Yup.object({
    activity: Yup.string().oneOf(["Indoor Seating", "Outdoor Seating"], "Select either indoor or outdoor").required("Option is Required"),
    date:Yup.string().required("Date is required"),
    occasion: Yup.string().required("occasion is required"),
    diners: Yup.string().required("No. of Diner is required"),
    time: Yup.string().required("Time is required")

  })
  

    let [formSubmitted, setFormsubmitted] = useState(false)


    let steps = [
        {title: 'First', description: 'Book Reservation'},
        {title: 'Second', description: "Confirm Reservation"},
    ]

    let {activeStep} = useSteps({
       index:  1,
        count: steps.length
    })

    


    let toast = useToast()


    let mobileView = useBreakpointValue({
        base: true,
        md: false
    })


    // Thhis is to target the md or tablet/ipad view
    let tabletView = useBreakpointValue({
        md: true,
        lg: false
    })

    function onSubmit (values, actions){

        //I'm wrapping setTimeout around console.log and co. here bcos i want to create illusion of a loading sign.  
          setTimeout((e) => {
           console.log("Form Data:",values)
           actions.setSubmitting(false)
           //actions.resetForm() //this reset the values typed by users to empty
           setFormsubmitted(true)
      
        }, 500)
      
      } 
       

      // Everthing for OrderOnline
      let [alertMessage, setAlertmessage] = useState("")
      let [alerterrorMessage, setalerterrorMessage] = useState("")
      let navigate = useNavigate()


//Creating Form validation

let initialValues2= {
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    message:"",
    check: ""
  }



  let validationSchema2 = Yup.object({
    firstname:Yup.string().required("First Name Required"),
    lastname: Yup.string().required("Last Name Required"),
    email: Yup.string().email("Invalid email format").required(" Email Required"),
    phone: Yup.string().required("Phone Number Required"),
    message:Yup.string().min(25, "Minimum characters is 25"),
    check: Yup.string().required("Please check this box if you want to proceed.")

  })


  let onSubmit2 = async(values, actions) => {

    setTimeout((e)=>{
        actions.setSubmitting(false)
    },30000)

    
    let pro= new Promise((resolve, reject)=>{

        setTimeout(() => {
            reject()
        },30000);
    })

    toast.promise(pro, {
        loading: {
            title: "Reservation pending",
            description: "PLease wait"
        },
        success: {
           title: "Looks great", 
            description: "Reservation resolved"
            },
        error: {
            title: "Promise failed",
            description: "Looks like we encountered a problem. Please wait a few minutes and try again. If the problem persist, please contact support@littlelemon.com"
             }
    })

    //Try this code which might bring an error
    try{
        let response = await axios.post("http://127.0.0.1:8000/api/userbookings/", {
            first_name: values.firstname,
            reservation_date: bookDate,
            reservation_slot: bookTime,
        })
        console.log("Success in submitting data", response.data)


        setAlertmessage(`Your Reservation has been confirmed ${values.firstname}, check your email.`)       

        toast.closeAll()
        
        let pro2 = new Promise((resolve, reject)=>{
            setTimeout(() => {
                resolve()
            }, 100);
        })

        toast.promise(pro2,{
            loading: {
                title: "Promise pending",
                description: "PLease wait"
            },
            success: {
                title: "Looks great",
                description: "Reservation resolved"
            },
            error: {
                title: "Promise failed",
                description: "Something wrong"
            }
        })


    }

    //If the error occurs, execute this
    catch(error){
        console.error("Error submitting form:", error.message )
        setalerterrorMessage("Something went wrong, please try again later!")
    }

      setTimeout((e)=> {
        console.log("Form Data:", values)
        //actions.resetForm()        
        },1000) 


        setTimeout((e) =>{
            setAlertmessage("")
            actions.resetForm({values: {firstname: "", lastname: "", email: "", message: ""}})
        }, 10000)

    }



   function onClose(){    
    setAlertmessage("")
   } 

   let onClose2 = () => {
    setalerterrorMessage("")
   }


   let [message, setMessage] = useState("")
   let [reservedSlots, setReservedSlots] = useState([])
  
   useEffect((e)=>{

    axios.get(`http://127.0.0.1:8000/api/userbookings/?date=${bookDate}` )
    .then(response => {
        setMessage(response.data.results)
        let slots = message.map(f=> f.reservation_slot) // This is like getting all the reservationsSlots and comparing if it containes/includes a certain figure and then disabled it. By using javascript .includes() on an array
        setReservedSlots(slots)
        console.log("Reserved slots data for bookings:", reservedSlots)
        console.log("sucess getting booking data", response.data.results)

    })
    .catch(error =>{
        console.log("Error getting bookings", error)

    })

}, [bookDate, reservedSlots])




  


if(mobileView){
    return(
        <div>
            <ReservationMob />
        </div>
    )
}


else{


if(formSubmitted){
    return(
        <div>

        {/*//Form Validation*/}
    <Formik
    onSubmit={onSubmit2} 
    initialValues={initialValues2}
    validationSchema={validationSchema2}
    >
         {(formik) => (

        <Form>

<Container p={5} minHeight="50vh" maxWidth="full" background="#495E57" style={{display: "grid", gridTemplateColumns: "1fr 1fr"}} paddingBottom={10} id="Top">
        <Box paddingTop={8}>
        <VStack mt={4}>
            <label for="firstname" style={{color:"white", marginLeft:tabletView? -210:-310}}>⭐ First Name</label>
                <Box fontSize="2xl" >
            
            <FormControl isInvalid={!!formik.errors.firstname && formik.touched.firstname}>
            <Field as={Input} name="firstname" id="firstname" color="#495E57" background="white" width={tabletView? "40vw": "30vw"} fontSize="2xl" placeholder="First Name" required/>

            <FormErrorMessage>
            <span style={{marginRight:5}}><FontAwesomeIcon icon={faWarning}/></span>
            <ErrorMessage name="firstname" />
            </FormErrorMessage>
            </FormControl>
                
                </Box>
            </VStack>

            <VStack mt={4}>
            <label for="Email" style={{color:"white", marginLeft:tabletView? -250: -350}}>⭐ Email</label>
                <Box fontSize="2xl">
            
            <FormControl isInvalid= {!!formik.errors.email && formik.touched.email}>
            <Field as={Input} color="#495E57" background="white" id="email" name="email" fontSize="2xl" width={tabletView? "40vw": "30vw"} placeholder="you@company.com" required/>

            <FormErrorMessage>
            <span style={{marginRight:5}}><FontAwesomeIcon icon={faWarning}/></span>
            <ErrorMessage name="email"/>
            </FormErrorMessage>
            </FormControl>
            
                </Box>
            </VStack>



            <VStack  mt={8} spacing={8} style={{color:"white", marginLeft:tabletView? -40:-10}}>
             
            <HStack mt={4} width={tabletView? "80%" :"59%"} justifyContent="space-between">

                <HStack spacing={tabletView? 4:8} onClick={(e)=> setFormsubmitted(null)} cursor="pointer">
                <FontAwesomeIcon icon={faCalendar} size="2xl" />
                {/*i'm using instanceof because values.date might not always be a date. Basically it means it's coming from date or created from the date template or blueprint*/}
                <FormikConsumer>{({values}) => <Text fontSize="xl">{values.date instanceof Date? values.date.toDateString() : "Select Date"}</Text>}</FormikConsumer>
                </HStack>

                <HStack spacing={tabletView?4:8} onClick={(e)=> setFormsubmitted(null)} cursor="pointer">
                <FontAwesomeIcon icon={faUser} size="2xl"/>

                <FormikConsumer>{({values}) => <Text fontSize="xl">{values.diners? values.diners: "Select Diner"}</Text> }</FormikConsumer>

                </HStack>

            </HStack>


            <HStack mt={4} width={tabletView? "80%":"59%"} justifyContent="space-between">

                <HStack spacing={tabletView? 4:8} onClick={(e)=> setFormsubmitted(null)} cursor="pointer">
                <FontAwesomeIcon icon={faClock} size="2xl"/>

                <FormikConsumer>{({values}) => <Text fontSize="xl">{values.time? (values.time>12? `${values.time-12}:00 PM`: `${values.time}:00 AM`) : "Select Time"}</Text> }</FormikConsumer>

                </HStack>

                <HStack spacing={tabletView? 4:8} onClick={(e)=> setFormsubmitted(null)} cursor="pointer">
                <FontAwesomeIcon icon={faWineGlass} size="2xl"/>
                <FormikConsumer>{({values}) => <Text fontSize="xl">{values.occasion? values.occasion: "Select Occasion"}</Text>}</FormikConsumer>
                </HStack>

            </HStack>
                <Box textAlign="center">

                <FormikConsumer>{({values}) => <Heading  as="h2" fontSize="2xl">{values.activity}</Heading>}</FormikConsumer>


                <HStack spacing={4} mt={5}>
                
                <FormControl isInvalid={formik.errors.check && formik.touched.check}>
                <Field as={Checkbox} id="check" name="check" required>You agree to our friendly  <Link to="/privacyPolicy" style={{fontWeight:"bold", textDecoration: "underline"}}>Privacy Policy</Link></Field>
                <FormErrorMessage>
                <span style={{marginRight:5}}><FontAwesomeIcon icon={faWarning}/></span>
                <ErrorMessage name="check"/>
                </FormErrorMessage>
                
                </FormControl>
                </HStack>
                
                </Box>


            </VStack>



        </Box> 
    


        <Box paddingTop={8}>
            <VStack mt={4}>
            <label for="lastname" style={{color:"white", marginLeft:tabletView? -210: -310}}>⭐ Last Name</label>
                <Box fontSize="2xl" >
            
            <FormControl isInvalid={!!formik.errors.lastname && formik.touched.lastname} >
            <Field as={Input} name="lastname" id="lastname" color="#495E57" background="white" width={tabletView? "40vw":"30vw"} fontSize="2xl" placeholder="Last Name" required/>

            <FormErrorMessage>
            <span style={{marginRight:5}}><FontAwesomeIcon icon={faWarning}/></span>
            <ErrorMessage name="lastname" />
            </FormErrorMessage>
            </FormControl>
                
                </Box>
            </VStack>

            
            <VStack mt={4}>
            <label for="phone" style={{color:"white", marginLeft:tabletView? -180:-280}}>⭐ Phone Number</label>
                <Box fontSize="2xl" >
            
            <FormControl isInvalid={!!formik.errors.phone && formik.touched.phone}>
            <InputGroup>
            <InputLeftAddon children={<FontAwesomeIcon icon={faPhone}/>} />
            <Field as={PhoneInput} flags={flags} defaultCountry="US" selected={formik.values.phone} onChange={(phone) => formik.setFieldValue("phone", phone)} name="phone" id="phone" color="#495E57" background="white" style={{width:tabletView? 260:360, marginLeft: 5}} fontSize="2xl" placeholder="Phone Number" required/>
            </InputGroup>
            <FormErrorMessage>
            <span style={{marginRight:5}}><FontAwesomeIcon icon={faWarning}/></span>
            <ErrorMessage name="phone" />
            </FormErrorMessage>
            </FormControl>
                
                </Box>
            </VStack>

            
            <VStack mt={4}>
                <label for="message" style={{color:"white", marginLeft:-tabletView? -190:-270}}>Special Requests</label>
                    <Box >
                
                <FormControl isInvalid={!!formik.errors.message && formik.touched.message}>
                <Field as={Textarea} minHeight={150} name="message" id="message" color="#495E57" background="white" fontSize="2xl" width={tabletView? "40vw":"30vw"} placeholder="Comment" />
            
                <FormErrorMessage>
                <span style={{marginRight:5}}><FontAwesomeIcon icon={faWarning}/></span>
                <ErrorMessage name="message"/>
                </FormErrorMessage>
                </FormControl>
                    </Box>
            </VStack>
    </Box>



</Container>


<Center>
<Container mt={10} alignItems="start" minHeight="50vh" maxWidth="full" style={{display: "grid", gridTemplateColumns: "1fr 1fr 1fr", gap:tabletView? 15:30,  paddingLeft:tabletView? 40: 100, paddingRight:tabletView? 40: 100} }>  
    <Box overflow="hidden" borderRadius={10}>
    <Image src={indoor} borderRadius={10} width={420} height={275}  objectFit="cover" _hover={{transform: "scale(1.1)"}} transition="transform 0.3s ease-in-out" />
    </Box>
    <Box overflow="hidden" borderRadius={10}>
    <Image src={grilledFish} width={420} height={275} borderRadius={10} objectFit="cover"  _hover={{transform: "scale(1.1)"}} transition="transform 0.3s ease-in-out"/>
    </Box>
    <Box overflow="hidden" borderRadius={10}>
    <Image src={pastalm} width={420} height={275} borderRadius={10} objectFit="cover"  _hover={{transform: "scale(1.1)"}} transition="transform 0.3s ease-in-out"/>
    </Box>

</Container>
</Center>

<Center mt={tabletView? -20:10} >
<Button type="submit" isDisabled={!formik.values.time} isLoading={formik.isSubmitting} borderRadius={10} p={6} paddingLeft={20} paddingRight={20} marginBottom={20} marginTop={-10} background="#F4CE14" fontWeight="bold">Confirm Reservation</Button><br/>
</Center>


<Stepper index={2} pl={tabletView? 100:200} pr={tabletView? 100:200} mt={-10} mb={5}>
    {/* Execute a function for each item */}
    {steps.map((f, index)=>{
    return <Step key={index}>
        <StepIndicator>
            <StepStatus complete={<StepIcon />} incomplete={<StepNumber/>} active={<StepNumber />} />

        </StepIndicator>

        <Box>
            <StepTitle fontSize="xl">{f.title}</StepTitle>
            <StepDescription fontSize="xl" >{f.description}</StepDescription>
        </Box>

        <StepSeparator />

    </Step>
    })}
</Stepper>




{/*validation ends here */}
{/*this is so i can use FormikConsumer */}


<AlertDialog
isOpen={alertMessage}
onClose={onClose}>

<AlertDialogOverlay>
    <AlertDialogContent>
        <AlertDialogHeader color="#495E57">
            All Good!
         <Center> <FontAwesomeIcon icon={faCheckCircle} size="2xl"/> </Center>
            <AlertDialogCloseButton />
        </AlertDialogHeader>
        <AlertDialogBody fontSize="x-large" color="#495E57">
            {alertMessage}<br/>  <FormikConsumer>{({values}) => <Text fontSize="md" mt={5}>Your table for {values.occasion} of {values.diners} is reserved for { values.date instanceof Date && values.date.toDateString()} at {values.time>12? `${values.time-12}:00 PM`: `${values.time}:00 AM`}.</Text>}</FormikConsumer>

        </AlertDialogBody>
    </AlertDialogContent>

</AlertDialogOverlay>
</AlertDialog> 




<AlertDialog
isOpen={alerterrorMessage}
onClose={onClose2}>

<AlertDialogOverlay>
    <AlertDialogContent backgroundColor= "#FF8A65">
        <AlertDialogHeader paddingTop={5} fontWeight="bold">
            Oops!        
            <AlertDialogCloseButton />
        </AlertDialogHeader>
        <AlertDialogBody paddingBottom={5}>
            {alerterrorMessage}<br/> 

        </AlertDialogBody>
    </AlertDialogContent>

</AlertDialogOverlay>
</AlertDialog> 


</Form>
)}


</Formik>
{/*validation ends here */}



</div>
    )
}

else{

return(
    <div> 

         {/*//Form Validation*/} 

         {/* Nothing should interfere with the 3F's that is Formik, (formik) sub and Form. 
Also sunmit button dont need onsubmit because it's part of the form*/ }

        <Formik
        onSubmit={onSubmit} 
        initialValues={initialValues}
        validationSchema={validationSchema}  
        >

        {(formik) => (

        <Form>


    <Box p={5} minHeight="50vh" background="#495E57" paddingBottom={10}>
              
        <VStack spacing={5} alignItems="start" marginLeft={30} paddingLeft={tabletView? 0: 20}>
                
            <Heading as= "h1" size="3xl" color="#F4CE14" paddingTop={10} id="Top">
                    Reservations
             </Heading><br/>
                   
        </VStack>

        
            
            <Box>
                <FormControl isInvalid={!!formik.errors.activity && formik.touched.activity}>

            <RadioGroup>

            <HStack  alignItems="start" marginLeft={30} paddingLeft={tabletView? 2: 20} width= {tabletView? "90%":"75%"} justifyContent="space-between" >
                <div>
                <HStack spacing={20} color="white" fontSize="2xl">
                <label for="radio">Indoor seating </label>
                <Field as={Radio} name="activity" value="Indoor Seating" id="radio"/>
                </HStack>
                <FormErrorMessage>
                <span style={{marginRight:5}}><FontAwesomeIcon icon={faWarning}/></span>
                <ErrorMessage name="activity"/>
                </FormErrorMessage>
                </div>

                <div>
                <HStack spacing={20} color="white" fontSize="2xl">
                <label for="radio2">Outdoor seating</label>
                <Field as={Radio} name="activity" value="Outdoor Seating" id="radio2"/>
                </HStack>
                <FormErrorMessage>
                <span style={{marginRight:5}}><FontAwesomeIcon icon={faWarning}/></span>
                <ErrorMessage name="activity"/>
                </FormErrorMessage>
                </div>
                
            </HStack>
            </RadioGroup>

                </FormControl>

            </Box> 
        

        <Container style={{display: "grid", gridTemplateColumns: "1fr 1fr", gap:tabletView? 50: 250}} maxWidth="full" paddingBottom={10} >


            <Box alignItems="start" marginLeft={tabletView? 5:30} paddingLeft={tabletView? -20: 20}>
                   
                <VStack mt={8} borderRadius={8}>

                    
                    <FormControl isInvalid= {!!formik.errors.date && formik.touched.date}>
                    <FormLabel htmlFor="date" style={{color:"white"}}>Date</FormLabel>
                    <InputGroup >
                    <InputLeftElement children={ <FontAwesomeIcon color="#495E57" icon={faCalendar} size="2xl"/>} />
                    <Select as={DatePicker} style={{textAlign: "center"}} popperPlacement="top" minDate={new Date()} color="#495E57" background="white"  placeholderText="....Select Date" selected={formik.values.date} onChange={(date) => formik.setFieldValue("date", date)} fontSize="2xl" name="date" id="date" />
                    </InputGroup>
                    <FormErrorMessage>
                    <span style={{marginRight:5}}><FontAwesomeIcon icon={faWarning}/></span>   
                    <ErrorMessage name="date"/>
                    </FormErrorMessage>
                    </FormControl>
                    

                </VStack>


                <VStack mt={8}>
                <FormControl isInvalid= {!!formik.errors.diners && formik.touched.diners}>
                <FormLabel htmlFor="diners" style={{color:"white"}}>Number of Diners</FormLabel>
                <InputGroup>
                <InputLeftElement children={<FontAwesomeIcon size="2xl" color="#495E57" icon={faImagePortrait} />}/>
                <Field style={{textAlign: "center"}} as={Select} color="#495E57" background="white" id="diners" name="diners" fontSize="2xl" width={tabletView? "40vw":"30vw"} >
                    <optgroup label= "Table 1">
                   <option value="" disabled>No. of Diners</option>
                   <option> 1 Diner</option>
                   <option>3 Diners</option>
                   <option>5 Diners</option>
                   <option>7 Diners</option>
                   <option>9 Diners</option>
                    </optgroup>
        
                    <optgroup label="Table 2">
                    <option>2 Diners</option>
                    <option>4 Diners</option>
                    <option>6 Diners</option>
                    <option>8 Diners</option>
                    </optgroup>
            
                </Field>
                </InputGroup>
                <FormErrorMessage>
                    <span style={{marginRight:5}}><FontAwesomeIcon icon={faWarning}/></span>
                    <ErrorMessage name="diners"/>
                    </FormErrorMessage>
                </FormControl>
    
                </VStack>
            </Box>


            <Box mt={8}>
                <VStack >                
                <FormControl isInvalid={!!formik.errors.occasion && formik.touched.occasion}>
                <FormLabel htmlFor="occasion" style={{color:"white"}}>Occasion</FormLabel>
                <InputGroup>
                <InputLeftElement children={<FontAwesomeIcon size="2xl" color="#495E57" icon={faWineGlassEmpty}/>}/>
                <Field as={Select} style={{textAlign: "center"}} name="occasion" id="occasion" color="#495E57" background="white" width={tabletView? "40vw":"30vw"} fontSize="2xl" >
                    <option value="" disabled>Occasion</option>
                    <option >Birthday </option>
                    <option>Engagement  </option>
                    <option>Anniversary</option>
                </Field>
                </InputGroup>
                <FormErrorMessage>
                <span style={{marginRight:5}}><FontAwesomeIcon icon={faWarning}/></span>
                <ErrorMessage name="occasion" />
                </FormErrorMessage>
                </FormControl>
                    
                </VStack>


                <VStack mt={8}>                    
                    <FormControl isInvalid={!!formik.errors.time && formik.touched.time}>
                    <FormLabel htmlFor="time" style={{color:"white"}}>Time</FormLabel>
                    <InputGroup>
                    <InputLeftElement children={<FontAwesomeIcon size="2xl" color="#495E57" icon={faClock} />}/>
                    <Field style={{textAlign: "center"}} as={Select}  name="time" id="time"  background="white" fontSize="2xl" width={tabletView? "40vw":"30vw"} >

                    {/*The powerful .includes() javascript checks if an array contains certain item. Specifically designed for arrays but if i want for objects I can extract the values of the keys/properties first into a separate array */}    
                    <optgroup label="Time 10-12 pm">
                    <option value="" disabled>Select Time</option>,
                    <option value={reservedSlots.includes(10)? "":"10"} disabled={reservedSlots.includes(10)} >10:00 am</option>
                    <option value={reservedSlots.includes(11)? "":"11"} disabled={reservedSlots.includes(11)} >11:00 am</option>
                    <option value={reservedSlots.includes(12)? "":"12"} disabled={reservedSlots.includes(12)}>12:00 pm</option>
                    </optgroup>

                    <optgroup label="Time 8-10 pm">
                    <option value={reservedSlots.includes(17)? "":"17"} disabled={reservedSlots.includes(17)}>5:00 pm</option>
                    <option value={reservedSlots.includes(18)? "":"18"} disabled={reservedSlots.includes(18)} >6:00 pm</option>
                    <option value={reservedSlots.includes(20)? "":"20"} disabled={reservedSlots.includes(20)} >8:00 pm</option>
                    </optgroup>

                    </Field>
                    </InputGroup>
                    <FormErrorMessage>
                    <span style={{marginRight:5}}><FontAwesomeIcon icon={faWarning}/></span>
                    <ErrorMessage name="time"/>
                    </FormErrorMessage>
                    </FormControl>

                </VStack>
            </Box>

        </Container>

    </Box>

    
        <Container mt={10} minHeight="50vh" maxWidth="full" style={{display: "grid", gridTemplateColumns: "1fr 1fr 1fr", gap:tabletView? 15: 30,  paddingLeft: tabletView? 40: 100, paddingRight: tabletView? 40: 100} }>  
        <Box overflow="hidden" borderRadius={10}>
        <Image src={lemonc} width={420} height={275} borderRadius={10}  objectFit="cover" _hover={{transform: "scale(1.1)"}} transition="transform 0.3s ease-in-out" />
        </Box>
        <Box overflow="hidden" borderRadius={10}>
        <Image src={chefs} width={420} height={275} borderRadius={10} objectFit="cover" _hover={{transform: "scale(1.1)"}} transition="transform 0.3s ease-in-out" />
        </Box>
        <Box overflow="hidden" borderRadius={10}>
        <Image src={restaurant} width={420} height={275} borderRadius={10} objectFit="cover" _hover={{transform: "scale(1.1)"}} transition="transform 0.3s ease-in-out" />
        </Box>

        </Container>
            
        <Center mt={tabletView? -20:10}>
        <Button type="submit" borderRadius={10} p={6} paddingLeft={20} paddingRight={20} marginBottom={20} marginTop={-10} background="#F4CE14" fontWeight="bold" isLoading={formik.isSubmitting}>Reserve a Table</Button>
        </Center>


 


    <Stepper index ={activeStep} paddingLeft={tabletView?100: 200} paddingRight={tabletView? 100: 200}  marginBottom={5} marginTop={-10}>
    {/* Execute a function for each item   */}
    {steps.map((f, index)=>{
    return  <Step key ={index}>
            <StepIndicator>
                <StepStatus complete={<StepIcon/>} incomplete={<StepNumber />} active={<stepNumber />} />
            </StepIndicator>

            <Box>
            <StepTitle fontSize="xl" >{f.title}</StepTitle>
            <StepDescription fontSize="xl">{f.description}</StepDescription>
            </Box>

            <StepSeparator />


        </Step>
    })}


    </Stepper>

{/* 
    <Box display="grid" gridTemplateColumns="1fr 1fr" margin={20} gap={20}>
    <div class="column">
        <div class="videowrap">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d11898.289517452584!2d-87.60853049433447!3d41.79442860243028!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880e2912ce6f7027%3A0xc0cfb5545d4a37b2!2sHyde%20Park%2C%20Chicago%2C%20IL%2C%20USA!5e0!3m2!1sen!2spt!4v1662384760663!5m2!1sen!2spt"
            width="600" height="450" style={{border:0}} allowfullscreen="" loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
    </div>


    <div class="column" style={{marginLeft:40}}>
        <FormikConsumer>{({values})=> {
            setbookDate( format(values.date.toDateString(), "yyyy-MM-dd"))
            setBookTime(values.time)
      return  <Heading style={{marginBottom: 40}}>Bookings For {values.date instanceof Date && format(values.date.toDateString(), "yyy-MM-dd") }</Heading>}}</FormikConsumer>

        
                {message && message.map((f, index)=>{
                return <div key={index}>      
                      <Text fontSize="2xl" >{f.first_name} - {f.reservation_slot>12? `${f.reservation_slot-12} PM`: `${f.reservation_slot} AM`}</Text>
                    </div>
                })}
                <Text fontSize="2xl">{message.length<1 && "No Bookings"}</Text>
                <Heading>{bookDate}</Heading>
                {message && message.map((f,index)=>{
                    return <div key={index}>
                        <Heading>{f.reservation_slot}</Heading>

                    </div>
                })}

                <Heading>{bookTime} booktime</Heading>
        

        <div id="bookings">
        </div>

    </div>
    
    </Box> */}

                
    </Form>
    )}

    </Formik>



   {/*validation ends here */}


   {/* Everthing here is coming from OrderOnline*/}
          
             

    </div>

    )

}

}
}

export default Reservation