import { Heading, useBreakpointValue,Card, Center, CardBody, CardFooter, HStack, Container, Text, VStack, Button, Box, Image, InputAddon, AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogBody, AlertDialogHeader, AlertDialogCloseButton, InputGroup, InputRightElement, Checkbox } from "@chakra-ui/react"
import {faEye, faEyeSlash} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import React, {useState, useEffect} from "react"
import {Formik, Form, Field, ErrorMessage } from "formik"
import {FormErrorMessage, FormControl, Input} from "@chakra-ui/react"
import * as Yup from 'yup'
import Login from "./Login";
import ForgotPass from "./ForgotPass";

import { getAuth, sendPasswordResetEmail, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import app from './firebase';

import { useNavigate } from "react-router-dom";
import SigninMob from "./Signin Mob";

import {useSpring, animated} from 'react-spring'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import unsplash from "./Assets/unsplash.jpg"
import logoPng from "./Assets/logo.png"
import lem1 from "./Assets/lem1.jpg"
import lm1 from "./Assets/lm1.jpg"
import indoor3 from "./Assets/indoor3.jpg"

import kphoto from "./kphoto.jpg"



function Signin({setsuccessfulSignin, successfulSignin}){

  let mobileView = useBreakpointValue({
    base: true,
    md: false
  })

  let props = useSpring({
    opacity: 1,
    from: {opacity: 0}
  })


  // This is to target the md or tablet/ipad view
  let tabletView = useBreakpointValue({
    md: true,
    lg: false
  })



  let navigate = useNavigate()
  let [hover, sethover] = useState(false)
  let [alerterrorMessage, setAlerterrromessage] = useState("")
  let [visiblePass, setVisiblePass] = useState(false)
  let [login, setLogin] = useState(false)
  let [forgot, setforgot] = useState(false)

  //let [rememberMe, setRememberMe] = useState(false)
  

  let onClose = () => {
    setAlerterrromessage("")
  }


  let initialValues = {
    email: "",
    password: "",
    rememberMe: false
  }


  let validationSchema = Yup.object({
    email: Yup.string().required("Email required").email("Invalid email format"),
    password: Yup.string().required("Password required"),
    rememberMe: Yup.string()
  })

  let onSubmit= async (values, actions) => {

// try this code which might bring an error
    try{
      let auth = getAuth(app)
//The waiting period
      await signInWithEmailAndPassword(auth, values.email, values.password)
      console.log("Signed in with email and password successfully")
      // This property means currently signed in user email verified
      if(auth.currentUser.emailVerified){
      setsuccessfulSignin(true)
    
      //storing user data in the browser's local storage
      values.rememberMe && localStorage.setItem("UserLoggedIn", JSON.stringify(values))
      navigate("/dashboard")
      }
      else{
        //setsuccessfulSignin(false)
        setAlerterrromessage("Email is not verified. Please verify your email")
      }
    }


// When the errors occurs execute this
    catch (error) {
      console.error("There was an error signing in:", error.message)
      setAlerterrromessage("Something went wrong, please try again later!")
    }

    setTimeout((e)=> {
      actions.setSubmitting(false)
      actions.resetForm()
    },500)

  }


  // Getting the user data from the browser's local storage
 useEffect((e)=>{
  
  let func = async()=>{
    try{
        let auth = getAuth(app)
        let storedData = localStorage.getItem("UserLoggedIn")
        // Converting stored data back to Javascript object
        let userData = JSON.parse(storedData)
        signInWithEmailAndPassword(auth, userData.email, userData.password )
        console.log(" Success in getting stored Data")
        setsuccessfulSignin(true)
        navigate("/dashboard")
      }
    
    catch(error){
      console.error("Error getting stored data:", error.message)
    }
  }

  return()=>{
    func()
  }  
 },[])



  let signoutHandler = async()=>{

    // try this code which might bring an error
    try{
      let auth = getAuth(app)
    // Waiting period here
    await signOut(auth)
      console.log("You've signed out successfully")
      setsuccessfulSignin(false)
    }
    // when the error occures execute this
    catch(errors) {
      console.errors("There was an error signing out", errors.message)
    }

  }  


   if (mobileView){
    return(
      <div>
        <SigninMob successfulSignin={successfulSignin} setsuccessfulSignin={setsuccessfulSignin}/>

      </div>
    )
   }


   else{

    return(
      
        
                    <div>
                        <Box p={5} minHeight="40vh" background="#495E57" paddingBottom={10}>

                        <VStack spacing={5} alignItems="start" marginLeft={30} paddingLeft={tabletView? 0:20}>

                        <Heading as= "h1" size="3xl" color="#F4CE14" paddingTop={10} id="Top">
                         Signin
                        </Heading><br/>
                        </VStack>


 
                    <Container position="relative" minHeight="40vh" minWidth="full" p={15} style={{display: "grid", gridTemplateColumns: "1fr 1fr",paddingLeft:tabletView? 20:100, paddingBottom: 50}}>
                    <Box>
                    <Box mt={8}>
                    <Heading as= "h1" size="2xl" color="#F4CE14" mt={-30}>
                    Little Lemon
                    </Heading>
                    <Heading as="h2" color="white">Chicago</Heading>
                    </Box>
                   
               {tabletView?     <Text width="50vw" color="white" fontSize="xl" mt={5} >
                    We are a family owned Mediterranean restaurant 
                    focused on traditional recipes served with a
                    twist. At Little Lemon, we pride ourselves on
                    being more than just a dining destination; we're<br/>
                    a family-oriented Mediterranean haven where<br/>
                    traditions come to life with a contemporary flair.<br/>
                    Our commitment is to bring you an authentic taste<br/>
                    of the Mediterranean, weaving together <br/>
                    time-honoured recipes and innovative culinary<br/>
                    techniques to create a symphony of flavors that<br/>
                    dance on the plate.<br/>
                    </Text>

                    :
                    <Text color="white" fontSize="xl" mt={5} >
                    We are a family owned Mediterranean restaurant <br/>
                    focused on traditional recipes served with a<br/>
                    twist. At Little Lemon, we pride ourselves on<br/>
                    being more than just a dining destination; we're<br/>
                    a family-oriented Mediterranean haven where<br/>
                    traditions come to life with a contemporary flair.<br/>
                    Our commitment is to bring you an authentic taste<br/>
                    of the Mediterranean, weaving together <br/>
                    time-honoured recipes and innovative culinary<br/>
                    techniques to create a symphony of flavors that<br/>
                    dance on the plate.<br/>
                    </Text>  }
                    
                    
                </Box>


                    <Box position= "absolute" right={tabletView? 10:20} bottom={20} transform="translate(0%, 5%)">
                    <Box borderRadius={10} position="relative">
                <Box overflow="hidden" borderRadius={10} height={427} >
                <Image height={427} width={640} transform= {hover && "scale(1.1)"} transition="transform 0.3s ease-in-out" src={unsplash} borderRadius={10} onMouseEnter={(e)=> sethover(true)} onMouseLeave={(e)=> sethover(false)}/>
                </Box>


                {!login && !forgot? 

                <Card p={10}  borderRadius={20} position="absolute" top={60} right={50}  transform="translate(-8%, -50%)">
                <Center>
                <Image src={logoPng} mt={-5} width={200} />
                </Center>

                <Heading marginBottom={8} fontSize="2xl">Welcome! Log in to your account </Heading>
                <Text marginBottom={8}>Log in to Little Lemon restaurant to continue to order<br/>online today, tomorrow and beyond.</Text>

                
                {/*Creating Form here. nothing shoud interfere with the three f's ie Formik, formik sub and form*/}
                <Formik
                onSubmit={onSubmit}
                initialValues={initialValues}
                validationSchema={validationSchema}
                >

                {(formik) => (

                <Form>
                  
                <FormControl isInvalid={!!formik.errors.email && formik.touched.email} >
                <Field as={Input} type="email" placeholder="Email" name="email"  />
                <FormErrorMessage>
                <ErrorMessage name="email" />
                </FormErrorMessage>

                </FormControl>
                
 
                <FormControl isInvalid={!!formik.errors.password && formik.touched.password} mt={4} >
               
                <InputGroup>
                <InputRightElement children={<FontAwesomeIcon icon={visiblePass? faEyeSlash: faEye} onClick={(e)=> setVisiblePass(!visiblePass)} cursor="pointer" />} />
                <Field as={Input} type={visiblePass? "text": "password"} placeholder="Password" name="password"  />
                </InputGroup>
                <FormErrorMessage>
                <ErrorMessage name="password"/>
                </FormErrorMessage>


                </FormControl>

                
              
                <HStack width="100%" justifyContent="space-between">
                <Field as={Checkbox} name="rememberMe" id="rememberMe">Remember me</Field>  
                <Text mt={4}><span onClick={(e)=> setforgot(true)} style={{color: "#EE9972", fontWeight: "bold", cursor: "pointer", marginLeft:5}}>Forgot pasword?</span></Text>    
              
                </HStack>
              
                <Button type="submit" isLoading={formik.isSubmitting} mt={4} width="full" background="#F4CE14" fontWeight="bold" >Log In</Button>

                </Form>
                )}
                </Formik>
                <Text mt={4}>Don't have an account? <span onClick={(e)=> {
                  setLogin(true)
                  }} style={{color: "#EE9972", fontWeight: "bold", cursor: "pointer", marginLeft:5}}>Sign up</span></Text>
  
               
                </Card>

                //This nested () inner tenary operator means that check for additional conditions

                : (forgot? <ForgotPass/> : <Login login={login} setLogin={setLogin} />) }

            
                    </Box>
                    </Box>
                    
                </Container>
                    
                </Box>


                <AlertDialog
                isOpen={alerterrorMessage}  /* What will make it open is the alertMessage variable*/
                onClose={onClose}
                >

              <AlertDialogOverlay>
              <AlertDialogContent backgroundColor= "#FF8A65">
              <AlertDialogHeader paddingTop={5} fontWeight="bold">
              <AlertDialogCloseButton/>  
                Oops!
              </AlertDialogHeader >
              <AlertDialogBody paddingBottom={5}>
                {alerterrorMessage}<br/>
            
            </AlertDialogBody>
            </AlertDialogContent>
            </AlertDialogOverlay>

            </AlertDialog>


            <Container mt={10} mb={tabletView && -40} alignItems="start"  minHeight="50vh" maxWidth="full" style={{display: "grid", gridTemplateColumns: "1fr 1fr 1fr", gap: tabletView? 15:30,  paddingLeft: tabletView? 0:100, paddingRight: tabletView? 0:100} }>  
            <Box overflow="hidden" borderRadius={10}>
            <Image src={indoor3} width={420} height={275} borderRadius={10}  objectFit="cover" _hover={{transform: "scale(1.1)"}} transition="transform 0.3s ease-in-out" />
            </Box>
            <Box overflow="hidden" borderRadius={10}>
            <Image src={lm1} width={420} height={275} borderRadius={10} objectFit="cover" _hover={{transform: "scale(1.1)"}} transition="transform 0.3s ease-in-out" />
            </Box>
            <Box overflow="hidden" borderRadius={10}>
            <Image src={lem1} width={420} height={275} borderRadius={10} objectFit="cover" _hover={{transform: "scale(1.1)"}} transition="transform 0.3s ease-in-out" />
            </Box>

            </Container>
           
               
               
            </div> 


                  
  
    )
  }
}

export default Signin